import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Box, Typography } from '@mui/material';

const ZezlomowanePojazdy = () => {
    const [zezlomowane, setZezlomowane] = useState([]);

    useEffect(() => {
        const fetchPojazdy = async () => {
            try {
                const response = await fetch('https://autosolgasystem.pl/django-app/dbview/');
                const data = await response.json();
                // Filter vehicles with status 'zezlomowane'
                const zezlomowaneVehicles = data.filter(pojazd => pojazd.status === 'zezlomowany');
                setZezlomowane(zezlomowaneVehicles);
            } catch (error) {
                console.error('Error fetching pojazdy:', error);
            }
        };

        fetchPojazdy();
    }, []);

    return (
        <Box className="main-content">
            <Typography variant="h4" gutterBottom>Zezłomowane pojazdy</Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Model</TableCell>
                            <TableCell>Marka</TableCell>
                            <TableCell>VIN</TableCell>
                            <TableCell>Data Złomowania</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {zezlomowane.map((pojazd) => (
                            <TableRow key={pojazd.id}>
                                <TableCell>{pojazd.model}</TableCell>
                                <TableCell>{pojazd.marka}</TableCell>
                                <TableCell>{pojazd.vin}</TableCell>
                                <TableCell>{new Date().toLocaleDateString()}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            </Box>
            );
};

            export default ZezlomowanePojazdy;
