import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Collapse,
    DialogTitle,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Grid,
    FormHelperText
} from '@mui/material';
import { useOperationPopup } from './OperationConfirmationPopup'; // Importuj hook
import './styles.css';
import { useConfirmDelete } from './ConfirmDelete';

const OsobaPrywatna = ({ osoby, onAdd, onDelete, onEdit }) => {
    const [form, setForm] = useState({
        imie: '', nazwisko: '', pesel: '', adres: '', kod_pocztowy: '',
        email: '', telefon: '', nr_dowodu: '', prawo_jazdy: '', data_urodzenia: ''
    });
    const [filters, setFilters] = useState({
        imie: '', nazwisko: '', pesel: '', adres: '', kod_pocztowy: '',
        email: '', telefon: '', nr_dowodu: '', prawo_jazdy: '', data_urodzenia: ''
    });
    const [open, setOpen] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [editId, setEditId] = useState(null);
    const [fetchedOsoby, setFetchedOsoby] = useState([]);
    const [filtersVisible, setFiltersVisible] = useState(false); // Kontrola widoczności filtrów
    const [emailError, setEmailError] = useState(""); // Walidacja e-maila
    const { confirmDelete, ConfirmationDialog } = useConfirmDelete();
    const { showPopup, Popup } = useOperationPopup(); // Hook do obsługi popupu

    // Pobieranie danych z API po załadowaniu komponentu
    useEffect(() => {
        fetch('https://autosolgasystem.pl/django-app/dbview/osoba_disp')
            .then((response) => response.json())
            .then((data) => setFetchedOsoby(data))
            .catch((error) => console.error('Błąd pobierania danych:', error));
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters({ ...filters, [name]: value });
    };

    // Walidacja e-maila
    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();

        // Sprawdzenie, czy obowiązkowe pola są wypełnione
        if (!form.imie.trim() || !form.nazwisko.trim() || !form.pesel.trim() || !form.nr_dowodu.trim()) {
            alert("Imię, nazwisko, PESEL i numer dowodu są obowiązkowe!");
            return;
        }

        // Walidacja numeru PESEL (11 cyfr)
        const peselRegex = /^\d{11}$/;
        if (!peselRegex.test(form.pesel)) {
            alert("PESEL musi składać się z dokładnie 11 cyfr.");
            return;
        }

        // Walidacja e-maila (jeśli podany)
        if (form.email && !validateEmail(form.email)) {
            setEmailError("Podaj prawidłowy adres e-mail");
            return;
        } else {
            setEmailError("");
        }

        // Tworzenie obiektu do wysyłki
        const payload = { ...form, id: Date.now() };

        if (isEditMode) {
            fetch(`https://autosolgasystem.pl/django-app/dbview/update_osoba/${editId}/`, {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(payload),
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Błąd serwera");
                    }
                    return response.json();
                })
                .then((data) => {
                    console.log("Osoba zaktualizowana:", data);
                    setFetchedOsoby((prevOsoby) =>
                        prevOsoby.map((osoba) =>
                            osoba.id === editId ? { ...osoba, ...data } : osoba
                        )
                    );
                    handleCloseForm();
                })
                .catch((error) => {
                    console.error("Błąd podczas edytowania osoby:", error);
                    alert("Nie udało się zaktualizować osoby. Sprawdź szczegóły w konsoli.");
                });
        } else {
            fetch("https://autosolgasystem.pl/django-app/dbview/create_osoba", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(payload),
            })
                .then((response) => response.json())
                .then((data) => {
                    setFetchedOsoby((prev) => [...prev, data]);
                    showPopup("Rekord został pomyślnie dodany!");
                    handleCloseForm();
                })
                .catch((error) => {
                    console.error("Błąd dodawania osoby:", error);
                    alert("Nie udało się dodać osoby.");
                });
        }
    };



    const handleDeleteClick = async (id) => {
        const confirmed = await confirmDelete('Czy na pewno chcesz usunąć ten rekord?');
        if (confirmed) {
            // Usuwanie osoby prywatnej (DELETE request)
            fetch(`https://autosolgasystem.pl/django-app/dbview/delete_osoba/${id}/`, {
                method: 'DELETE',
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Błąd serwera');
                    }
                    return response.json();
                })
                .then(() => {
                    console.log("Osoba usunięta pomyślnie");
                    showPopup('Rekord został pomyślnie usunięty!');
                    setFetchedOsoby(fetchedOsoby.filter((osoba) => osoba.id !== id));
                })
                .catch((error) => {
                    console.error("Błąd podczas usuwania osoby:", error);
                    alert("Nie udało się usunąć osoby. Sprawdź szczegóły w konsoli.");
                });
        }
    };

    const handleEditClick = (osoba) => {
        setForm(osoba);
        setEditId(osoba.id);
        setIsEditMode(true);
        setOpen(true);
    };

    const handleCloseForm = () => {
        setOpen(false);
        setIsEditMode(false);
        setForm({
            imie: '', nazwisko: '', pesel: '', adres: '', kod_pocztowy: '',
            email: '', telefon: '', nr_dowodu: '', prawo_jazdy: '', data_urodzenia: ''
        });
    };

    const filteredOsoby = fetchedOsoby.filter((osoba) => {
        return Object.keys(filters).every((key) =>
            osoba[key]?.toLowerCase().includes(filters[key].toLowerCase())
        );
    });

    return (
        <Box className="main-content">
            <Typography variant="h4" gutterBottom>Osoby Prywatne</Typography>
            <Box className="button-group">
                <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
                    Dodaj Osobę
                </Button>
                <Button variant="outlined" color="secondary" onClick={() => setFiltersVisible(!filtersVisible)}>
                    {filtersVisible ? 'Ukryj Filtry' : 'Pokaż Filtry'}
                </Button>
            </Box>
            <Popup />

            {/* Filtry */}
            <Collapse in={filtersVisible}>
                <Box marginBottom={2} padding={2} border={1} borderColor="grey.300">
                    <Grid container spacing={2} >
                        {Object.keys(filters).map((field) => (
                            <Grid item xs={12} sm={3} key={field}>
                                <TextField
                                    name={field}
                                    label={field}
                                    value={filters[field]}
                                    onChange={handleFilterChange}
                                    fullWidth
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Collapse>

            {/* Tabela */}
            <TableContainer component={Paper} style={{ marginTop: '20px' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {['Imię', 'Nazwisko', 'PESEL', 'Adres', 'Kod Pocztowy', 'Email', 'Telefon', 'Nr Dowodu', 'Prawo Jazdy', 'Data Urodzenia', 'Akcje'].map((header) => (
                                <TableCell key={header}>{header}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredOsoby.map((osoba) => (
                            <TableRow key={osoba.id}>
                                <TableCell>{osoba.imie}</TableCell>
                                <TableCell>{osoba.nazwisko}</TableCell>
                                <TableCell>{osoba.pesel}</TableCell>
                                <TableCell>{osoba.adres}</TableCell>
                                <TableCell>{osoba.kod_pocztowy}</TableCell>
                                <TableCell>{osoba.email}</TableCell>
                                <TableCell>{osoba.telefon}</TableCell>
                                <TableCell>{osoba.nr_dowodu}</TableCell>
                                <TableCell>{osoba.prawo_jazdy}</TableCell>
                                <TableCell>{osoba.data_urodzenia}</TableCell>
                                <TableCell>
                                    <Button onClick={() => handleEditClick(osoba)}>Edytuj</Button>
                                    <Button onClick={() => handleDeleteClick(osoba.id)}>Usuń</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Formularz */}
            <Dialog open={open} onClose={handleCloseForm}>
                <DialogTitle>{isEditMode ? "Edytuj Osobę" : "Dodaj Osobę"}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        {/* IMIĘ */}
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="imie"
                                label="Imię"
                                value={form.imie}
                                onChange={handleInputChange}
                                fullWidth
                                required
                                error={!form.imie.trim()}
                                helperText={!form.imie.trim() ? "To pole jest wymagane" : ""}
                            />
                        </Grid>

                        {/* NAZWISKO */}
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="nazwisko"
                                label="Nazwisko"
                                value={form.nazwisko}
                                onChange={handleInputChange}
                                fullWidth
                                required
                                error={!form.nazwisko.trim()}
                                helperText={!form.nazwisko.trim() ? "To pole jest wymagane" : ""}
                            />
                        </Grid>

                        {/* PESEL */}
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="pesel"
                                label="PESEL"
                                value={form.pesel}
                                onChange={handleInputChange}
                                fullWidth
                                required
                                error={!/^\d{11}$/.test(form.pesel)}
                                helperText={!/^\d{11}$/.test(form.pesel) ? "PESEL musi mieć 11 cyfr" : ""}
                            />
                        </Grid>

                        {/* ADRES */}
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="adres"
                                label="Adres"
                                value={form.adres}
                                onChange={handleInputChange}
                                fullWidth
                            />
                        </Grid>

                        {/* KOD POCZTOWY */}
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="kod_pocztowy"
                                label="Kod Pocztowy"
                                value={form.kod_pocztowy}
                                onChange={handleInputChange}
                                fullWidth
                            />
                        </Grid>

                        {/* EMAIL */}
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="email"
                                label="Email"
                                value={form.email}
                                onChange={handleInputChange}
                                fullWidth
                                error={form.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(form.email)}
                                helperText={form.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(form.email) ? "Nieprawidłowy email" : ""}
                            />
                        </Grid>

                        {/* TELEFON */}
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="telefon"
                                label="Telefon"
                                value={form.telefon}
                                onChange={handleInputChange}
                                fullWidth
                            />
                        </Grid>

                        {/* NUMER DOWODU */}
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="nr_dowodu"
                                label="Numer Dowodu"
                                value={form.nr_dowodu}
                                onChange={handleInputChange}
                                fullWidth
                                required
                                error={!form.nr_dowodu.trim()}
                                helperText={!form.nr_dowodu.trim() ? "To pole jest wymagane" : ""}
                            />
                        </Grid>

                        {/* PRAWO JAZDY */}
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="prawo_jazdy"
                                label="Prawo Jazdy"
                                value={form.prawo_jazdy}
                                onChange={handleInputChange}
                                fullWidth
                            />
                        </Grid>

                        {/* DATA URODZENIA */}
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="data_urodzenia"
                                label="Data Urodzenia"
                                type="date"
                                value={form.data_urodzenia}
                                onChange={handleInputChange}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseForm} color="primary">Anuluj</Button>
                    <Button onClick={handleFormSubmit} color="primary">
                        {isEditMode ? "Zapisz" : "Dodaj"}
                    </Button>
                </DialogActions>
            </Dialog>

            <ConfirmationDialog />
        </Box>
    );
};

export default OsobaPrywatna;
