import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box } from '@mui/material';

const SprzedanePojazdy = () => {
    const [sprzedane, setSprzedane] = useState([]);

    useEffect(() => {
        const fetchPojazdy = async () => {
            try {
                const response = await fetch('https://autosolgasystem.pl/django-app/dbview/');
                const data = await response.json();
                // Filter vehicles with status 'sprzedany'
                const sprzedaneVehicles = data.filter(pojazd => pojazd.status === 'sprzedany');
                setSprzedane(sprzedaneVehicles);
            } catch (error) {
                console.error('Błąd pobierania pojazdów:', error);
            }
        };

        fetchPojazdy();
    }, []);

    return (
        <Box className="main-content">
            <Typography variant="h4" gutterBottom>Sprzedane pojazdy</Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Model</TableCell>
                            <TableCell>Marka</TableCell>
                            <TableCell>VIN</TableCell>
                            <TableCell>Data Sprzedaży</TableCell>
                            <TableCell>Cena Sprzedaży</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sprzedane.map((pojazd) => (
                            <TableRow key={pojazd.id}>
                                <TableCell>{pojazd.model || 'Brak danych'}</TableCell>
                                <TableCell>{pojazd.marka || 'Brak danych'}</TableCell>
                                <TableCell>{pojazd.vin || 'Brak danych'}</TableCell>
                                <TableCell>
                                    {pojazd.dataSprzedazy
                                        ? new Date(pojazd.dataSprzedazy).toLocaleDateString()
                                        : 'Brak danych'}
                                </TableCell>
                                <TableCell>
                                    {pojazd.sellPrice
                                        ? `${pojazd.sellPrice.toLocaleString('pl-PL')} zł`
                                        : 'Brak danych'}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default SprzedanePojazdy;