import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Grid
} from '@mui/material';
import { useOperationPopup } from './OperationConfirmationPopup'; // Importuj hook
import './styles.css'; // Import the CSS file
import { useConfirmDelete } from './ConfirmDelete'; // Import the hook for confirmation

const Firma = ({ firmy, onAdd, onDelete, onEdit }) => {
    const [form, setForm] = useState({
        nazwa_firmy: '',
        nip: '',
        adres: '',
        kod_pocztowy: '',
        email: '',
        telefon: ''
    });
    const [open, setOpen] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [editId, setEditId] = useState(null);
    const { showPopup, Popup } = useOperationPopup(); // Hook do obsługi popupu

    const [filters, setFilters] = useState({
        nazwa: '',
        nip: '',
        adres: '',
        kodPocztowy: '',
        email: '',
        telefon: ''
    });
    const [filtersVisible, setFiltersVisible] = useState(false); // Sterowanie widocznością filtrów
    const [fetchedFirmy, setFetchedFirmy] = useState([]);

    const { confirmDelete, ConfirmationDialog } = useConfirmDelete();

    // Pobieranie danych z API po załadowaniu komponentu
    useEffect(() => {
        fetch('https://autosolgasystem.pl/django-app/dbview/firma_disp') // Poprawny URL API
            .then((response) => {
                // Sprawdzamy, czy odpowiedź jest prawidłowa
                if (!response.ok) {
                    throw new Error('Błąd sieciowy: ' + response.statusText);
                }
                // Sprawdzamy, czy odpowiedź jest w formacie JSON
                const contentType = response.headers.get('Content-Type');
                if (!contentType || !contentType.includes('application/json')) {
                    throw new Error('Odpowiedź nie jest w formacie JSON');
                }
                return response.json();
            })
            .then((data) => setFetchedFirmy(data)) // Zapisujemy dane do stanu
            .catch((error) => {
                console.error('Błąd pobierania danych:', error);
                alert('Wystąpił błąd podczas pobierania danych');
            });
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    // Obsługa dodawania lub edytowania firmy (POST request)
    const handleFormSubmit = (e) => {
        e.preventDefault();

        const payload = {
            nazwa_firmy: form.nazwa_firmy,
            nip: form.nip,
            adres: form.adres,
            kod_pocztowy: form.kod_pocztowy,
            email: form.email || "",
            telefon: form.telefon
        };

        if (isEditMode) {
            // Aktualizacja firmy - PUT request
            fetch(`https://autosolgasystem.pl/django-app/dbview/update_firma/${editId}/`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Błąd serwera');
                    }
                    return response.json();
                })
                .then((data) => {
                    console.log("Firma zaktualizowana:", data);
                    setFetchedFirmy((prevFirmy) => prevFirmy.map(firma =>
                        firma.id === editId ? { ...firma, ...data } : firma
                    ));
                    setForm({ nazwa_firmy: '', nip: '', adres: '', kod_pocztowy: '', email: '', telefon: '' });
                    setOpen(false);
                    setIsEditMode(false);
                    setEditId(null);
                })
                .catch((error) => {
                    console.error("Błąd podczas edytowania firmy:", error);
                    alert("Nie udało się zaktualizować firmy. Sprawdź szczegóły w konsoli.");
                });
        } else {
            // Tworzenie nowej firmy - POST request
            fetch('https://autosolgasystem.pl/django-app/dbview/create_firma', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Błąd serwera');
                    }
                    return response.json();
                })
                .then((data) => {
                    console.log("Firma dodana:", data);
                    setFetchedFirmy((prevFirmy) => [...prevFirmy, data]); // Dodanie firmy do stanu
                    showPopup('Rekord został pomyślnie dodany!');
                    setForm({ nazwa_firmy: '', nip: '', adres: '', kod_pocztowy: '', email: '', telefon: '' });
                    setOpen(false);
                    setIsEditMode(false);
                    setEditId(null);
                })
                .catch((error) => {
                    console.error("Błąd podczas dodawania firmy:", error);
                    alert("Nie udało się dodać firmy. Sprawdź szczegóły w konsoli.");
                });
        }
    };


    const handleDeleteClick = async (id) => {
        const confirmed = await confirmDelete('Czy na pewno chcesz usunąć ten rekord?');
        if (confirmed) {
            // Usuwanie firmy - DELETE request
            fetch(`https://autosolgasystem.pl/django-app/dbview/delete_firma/${id}/`, {
                method: 'DELETE',
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Błąd serwera');
                    }
                    return response.json();
                })
                .then(() => {
                    console.log("Firma usunięta pomyślnie");
                    showPopup('Rekord został pomyślnie usunięty!');
                    setFetchedFirmy(fetchedFirmy.filter(firma => firma.id !== id));
                })
                .catch((error) => {
                    console.error("Błąd podczas usuwania firmy:", error);
                    alert("Nie udało się usunąć firmy. Sprawdź szczegóły w konsoli.");
                });
        }
    };

    const handleEditClick = (firma) => {
        setForm(firma);
        setEditId(firma.id);
        setIsEditMode(true);
        setOpen(true);
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters({ ...filters, [name]: value.toLowerCase() });
    };

    const filteredFirmy = fetchedFirmy.filter((firma) => {
        return (
            (firma.nazwa_firmy?.toLowerCase().includes(filters.nazwa) || '') &&
            (firma.nip?.toLowerCase().includes(filters.nip) || '') &&
            (firma.adres?.toLowerCase().includes(filters.adres) || '') &&
            (firma.kod_pocztowy?.toLowerCase().includes(filters.kodPocztowy) || '') &&
            (firma.email?.toLowerCase().includes(filters.email) || '') &&
            (firma.telefon?.toLowerCase().includes(filters.telefon) || '')
        );
    });

    return (
        <Box className="main-content">
            <Typography variant="h4" gutterBottom>Firma</Typography>
            <Box className="button-group">
                <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
                    Dodaj Firmę
                </Button>
                <Button variant="outlined" color="secondary" onClick={() => setFiltersVisible(!filtersVisible)}>
                    {filtersVisible ? 'Ukryj Filtry' : 'Pokaż Filtry'}
                </Button>
            </Box>
            <Popup />

            {/* Sekcja filtrów - ukrywanie/pokazywanie po kliknięciu */}
            <Collapse in={filtersVisible}>
                <Box marginBottom={2} padding={2} border={1} borderColor="grey.300">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                name="nazwa_firmy"
                                label="Nazwa firmy"
                                value={filters.nazwa_firmy}
                                onChange={handleFilterChange}
                                className="filter-field"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                name="nip"
                                label="NIP"
                                value={filters.nip}
                                onChange={handleFilterChange}
                                className="filter-field"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                name="adres"
                                label="Adres"
                                value={filters.adres}
                                onChange={handleFilterChange}
                                className="filter-field"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                name="kodPocztowy"
                                label="Kod Pocztowy"
                                value={filters.kodPocztowy}
                                onChange={handleFilterChange}
                                className="filter-field"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                name="email"
                                label="Email"
                                value={filters.email}
                                onChange={handleFilterChange}
                                className="filter-field"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                name="telefon"
                                label="Telefon"
                                value={filters.telefon}
                                onChange={handleFilterChange}
                                className="filter-field"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Collapse>

            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>{isEditMode ? 'Edytuj Firmę' : 'Dodaj Firmę'}</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleFormSubmit} className="form-container">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="nazwa_firmy"
                                    label="Nazwa firmy"
                                    value={form.nazwa_firmy}
                                    onChange={handleInputChange}
                                    required
                                    className="textfield"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="nip"
                                    label="NIP"
                                    value={form.nip}
                                    onChange={handleInputChange}
                                    required
                                    className="textfield"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="adres"
                                    label="Adres"
                                    value={form.adres}
                                    onChange={handleInputChange}
                                    className="textfield"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="kodPocztowy"
                                    label="Kod Pocztowy"
                                    value={form.kodPocztowy}
                                    onChange={handleInputChange}
                                    className="textfield"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="email"
                                    label="Email"
                                    value={form.email}
                                    onChange={handleInputChange}
                                    className="textfield"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="telefon"
                                    label="Telefon"
                                    value={form.telefon}
                                    onChange={handleInputChange}
                                    className="textfield"
                                />
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="primary">
                        Anuluj
                    </Button>
                    <Button onClick={handleFormSubmit} color="primary">
                        {isEditMode ? 'Zapisz' : 'Dodaj'}
                    </Button>
                </DialogActions>
            </Dialog>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Nazwa firmy</TableCell>
                            <TableCell>NIP</TableCell>
                            <TableCell>Adres</TableCell>
                            <TableCell>Kod Pocztowy</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Telefon</TableCell>
                            <TableCell>Akcje</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredFirmy.map((firma) => (
                            <TableRow key={firma.id}>
                                <TableCell>{firma.nazwa_firmy}</TableCell>
                                <TableCell>{firma.nip}</TableCell>
                                <TableCell>{firma.adres}</TableCell>
                                <TableCell>{firma.kod_pocztowy}</TableCell>
                                <TableCell>{firma.email}</TableCell>
                                <TableCell>{firma.telefon}</TableCell>
                                <TableCell>
                                    <Button onClick={() => handleEditClick(firma)}>Edytuj</Button>
                                    <Button onClick={() => handleDeleteClick(firma.id)}>Usuń</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <ConfirmationDialog />
        </Box>
    );
};

export default Firma;
