import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Snackbar,
    Alert,
} from '@mui/material';
import { useConfirmDelete } from './ConfirmDelete';
import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import { saveAs } from 'file-saver';

const UmowaNajmuPriv = () => {
    const [form, setForm] = useState({
        nazwa: '',
        data: '',
        godzina: '',
        dzien: '',
        dogodziny: '',
        dodzien: '',
        dodatkowe: '',
        selectedVehicle: '',
        selectedClient: '',
    });
    const [documents, setDocuments] = useState([]);
    const [flota, setFlota] = useState([]);
    const [osobyPrywatne, setOsobyPrywatne] = useState([]);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const { confirmDelete, ConfirmationDialog } = useConfirmDelete();

    // Funkcja do pobierania danych
    const fetchData = async () => {
        try {
            const [docsResponse, flotaResponse, osobyResponse] = await Promise.all([
                fetch('https://autosolgasystem.pl/django-app/dbview/dokumenty/'),
                fetch('https://autosolgasystem.pl/django-app/dbview/'),
                fetch('https://autosolgasystem.pl/django-app/dbview/osoba_disp'),
            ]);

            if (!docsResponse.ok || !flotaResponse.ok || !osobyResponse.ok) {
                throw new Error('Błąd pobierania danych');
            }

            const [docsData, flotaData, osobyData] = await Promise.all([
                docsResponse.json(),
                flotaResponse.json(),
                osobyResponse.json(),
            ]);

            const filteredDocuments = docsData.filter((doc) => doc.rodzaj === 'umowa najmu priv');
            setDocuments(filteredDocuments);
            setFlota(flotaData);
            setOsobyPrywatne(osobyData);
            setLoading(false);
        } catch (error) {
            console.error('Błąd pobierania danych:', error);
            setSnackbar({ open: true, message: 'Błąd pobierania danych', severity: 'error' });
            setLoading(false);
        }
    };

    // Pobieranie danych przy załadowaniu komponentu
    useEffect(() => {
        fetchData();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const handleSnackbarClose = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    const generateDocx = async () => {
        try {
            const response = await fetch('/UmowaNajmuPriv.docx');
            if (!response.ok) {
                throw new Error('Błąd pobierania szablonu');
            }

            const blob = await response.blob();
            const arrayBuffer = await blob.arrayBuffer();
            const zip = new PizZip(arrayBuffer);
            const doc = new Docxtemplater(zip, { paragraphLoop: true, lineBreaks: true });

            const selectedVehicle = flota.find((v) => v.id === form.selectedVehicle) || {};
            const selectedClient = osobyPrywatne.find((c) => c.id === form.selectedClient) || {};

            doc.setData({
                nazwa: form.nazwa,
                data: form.data,
                godzina: form.godzina,
                dzien: form.dzien,
                dogodziny: form.dogodziny,
                dodzien: form.dodzien,
                dodatkowe: form.dodatkowe,
                marka: selectedVehicle.marka,
                model: selectedVehicle.model,
                registrationNumber: selectedVehicle.registrationNumber,
                imie: selectedClient.imie,
                nazwisko: selectedClient.nazwisko,
                nrDowodu: selectedClient.nrDowodu,
                pesel: selectedClient.pesel,
                telefon: selectedClient.telefon,
                adres: selectedClient.adres,
                prawojazdy: selectedClient.prawoJazdy,
                dataurodzenia: selectedClient.dataUrodzenia,
                currentDate: new Date().toLocaleDateString(),
            });

            doc.render();
            const out = doc.getZip().generate({ type: 'blob' });
            saveAs(out, `${form.nazwa}.docx`);

            const formData = new FormData();
            formData.append('nazwa', form.nazwa);
            formData.append('data', form.data);
            formData.append('status', 'Nowy');
            formData.append('rodzaj', 'umowa najmu priv');
            formData.append('plik', out, `${form.nazwa}.docx`);

            const createResponse = await fetch('https://autosolgasystem.pl/django-app/dbview/dokumenty/create/', {
                method: 'POST',
                body: formData,
            });

            const result = await createResponse.json();
            if (result.status === 'success') {
                setSnackbar({ open: true, message: 'Dokument dodany pomyślnie', severity: 'success' });
                fetchData(); // Odśwież dane
                resetForm();
            } else {
                setSnackbar({ open: true, message: result.message, severity: 'error' });
            }
        } catch (error) {
            console.error('Błąd podczas generowania pliku:', error);
            setSnackbar({ open: true, message: 'Błąd podczas generowania pliku', severity: 'error' });
        }
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (!form.nazwa || !form.data || !form.selectedVehicle || !form.selectedClient) {
            setSnackbar({ open: true, message: 'Proszę wypełnić wszystkie wymagane pola.', severity: 'error' });
            return;
        }
        generateDocx();
    };

    const handleDeleteClick = async (id) => {
        const confirmed = await confirmDelete('Czy na pewno chcesz usunąć ten dokument?');
        if (confirmed) {
            try {
                const response = await fetch(`https://autosolgasystem.pl/django-app/dbview/dokumenty/delete/${id}/`, {
                    method: 'DELETE',
                });
                const result = await response.json();
                if (result.status === 'success') {
                    setSnackbar({ open: true, message: 'Dokument usunięty pomyślnie', severity: 'success' });
                    fetchData(); // Odśwież dane
                } else {
                    setSnackbar({ open: true, message: result.message, severity: 'error' });
                }
            } catch (error) {
                console.error('Błąd podczas usuwania dokumentu:', error);
                setSnackbar({ open: true, message: 'Błąd podczas usuwania dokumentu', severity: 'error' });
            }
        }
    };

    const resetForm = () => {
        setForm({
            nazwa: '',
            data: '',
            godzina: '',
            dzien: '',
            dogodziny: '',
            dodzien: '',
            dodatkowe: '',
            selectedVehicle: '',
            selectedClient: '',
        });
        setOpen(false);
    };

    if (loading) {
        return <p>Ładowanie danych...</p>;
    }

    return (
        <Box className="main-content">
            <Typography variant="h4" gutterBottom>Dokumenty - umowa najmu priv</Typography>
            <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
                Dodaj Dokument
            </Button>

            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Dodaj Dokument</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleFormSubmit}>
                        <TextField
                            name="nazwa"
                            label="Nazwa Dokumentu"
                            value={form.nazwa}
                            onChange={handleInputChange}
                            required
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            name="data"
                            label="Data"
                            type="date"
                            value={form.data}
                            onChange={handleInputChange}
                            required
                            fullWidth
                            margin="normal"
                            InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                            name="godzina"
                            label="Godzina"
                            value={form.godzina}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            name="dzien"
                            label="Dzień"
                            value={form.dzien}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            name="dogodziny"
                            label="Do Godziny"
                            value={form.dogodziny}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            name="dodzien"
                            label="Do Dnia"
                            value={form.dodzien}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            name="dodatkowe"
                            label="Dodatkowe Informacje"
                            value={form.dodatkowe}
                            onChange={handleInputChange}
                            fullWidth
                            multiline
                            margin="normal"
                        />
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Wybierz pojazd</InputLabel>
                            <Select
                                name="selectedVehicle"
                                value={form.selectedVehicle}
                                onChange={handleInputChange}
                                required
                            >
                                <MenuItem value=""><em>-- Wybierz pojazd --</em></MenuItem>
                                {flota.map((vehicle) => (
                                    <MenuItem key={vehicle.id} value={vehicle.id}>
                                        {vehicle.marka} - {vehicle.model}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth margin="normal">
                            <InputLabel>Wybierz kontrahenta</InputLabel>
                            <Select
                                name="selectedClient"
                                value={form.selectedClient}
                                onChange={handleInputChange}
                                required
                            >
                                <MenuItem value=""><em>-- Wybierz kontrahenta --</em></MenuItem>
                                {osobyPrywatne.map((client) => (
                                    <MenuItem key={client.id} value={client.id}>
                                        {client.imie} {client.nazwisko}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="secondary">
                        Anuluj
                    </Button>
                    <Button onClick={handleFormSubmit} color="primary">
                        Zapisz
                    </Button>
                </DialogActions>
            </Dialog>

            <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Nazwa</TableCell>
                            <TableCell>Data</TableCell>
                            <TableCell>Plik</TableCell>
                            <TableCell>Akcje</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {documents.map((doc) => (
                            <TableRow key={doc.id}>
                                <TableCell>{doc.nazwa}</TableCell>
                                <TableCell>{doc.data}</TableCell>
                                <TableCell>
                                    <Button
                                        onClick={() => window.open(`https://autosolgasystem.pl/media/${doc.plik}`, '_blank')}
                                        color="primary"
                                    >
                                        Pobierz
                                    </Button>
                                </TableCell>
                                <TableCell>
                                    <Button
                                        onClick={() => handleDeleteClick(doc.id)}
                                        color="secondary"
                                    >
                                        Usuń
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>

            <ConfirmationDialog />
        </Box>
    );
};

export default UmowaNajmuPriv;