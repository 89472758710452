import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Table,
    Typography,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    FormControlLabel,
} from '@mui/material';
import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import { saveAs } from 'file-saver';

const WydanieOdbiorSamochodu = ({ flota = [], kontrahenci = [] }) => {
    const [form, setForm] = useState({
        rentalAmount: '',
        fuelLevel: '',
        vehicleCondition: '',
        spareTire: false,
        firstAidKit: false,
        selectedVehicle: '',
        selectedClient: '',
        imie: '',
        nazwisko: '',
    });
    const [documents, setDocuments] = useState([]);
    const [open, setOpen] = useState(false);
    const [operationType, setOperationType] = useState(''); // 'wydanie' lub 'odbior'

    // Pobierz listę dokumentów przy pierwszym renderowaniu komponentu
    useEffect(() => {
        fetchDocuments();
    }, []);

    const fetchDocuments = async () => {
        const response = await fetch('https://autosolgasystem.pl/django-app/dbview/dokumenty/');
        if (!response.ok) {
            console.error('Błąd podczas pobierania dokumentów:', response.statusText);
            return;
        }
        const data = await response.json();
        const filteredDocuments = data.filter((doc) => doc.rodzaj === 'umowa');
        setDocuments(filteredDocuments);
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setForm({ ...form, [name]: type === 'checkbox' ? checked : value });
    };

    const handleOpenDialog = (type) => {
        setOperationType(type);
        setOpen(true);
    };

    const addDocumentToDatabase = async (document) => {
        const formData = new FormData();
        formData.append('nazwa', document.nazwa);
        formData.append('data', document.data);
        formData.append('plik', document.plik);
        formData.append('rodzaj', 'umowa');

        const response = await fetch('https://autosolgasystem.pl/django-app/dbview/dokumenty/create/', {
            method: 'POST',
            body: formData,
        });

        if (!response.ok) {
            console.error('Błąd podczas dodawania dokumentu:', response.statusText);
            return null;
        }

        const result = await response.json();
        return result.id;
    };

    const deleteDocumentFromDatabase = async (id) => {
        const response = await fetch(`https://autosolgasystem.pl/django-app/dbview/dokumenty/delete/${id}/`, {
            method: 'DELETE',
        });

        if (!response.ok) {
            console.error('Błąd podczas usuwania dokumentu:', response.statusText);
            return 'error';
        }

        const result = await response.json();
        return result.status;
    };

    const generateDocx = async () => {
        const templateUrl = operationType === 'wydanie' ? '/Protokol-odbioru.docx' : '/Protokol-wydania.docx';
        const response = await fetch(templateUrl);

        if (!response.ok) {
            console.error('Błąd podczas pobierania szablonu:', response.statusText);
            return;
        }

        const blob = await response.blob();
        const arrayBuffer = await blob.arrayBuffer();

        const zip = new PizZip(arrayBuffer);
        const doc = new Docxtemplater(zip, { paragraphLoop: true, lineBreaks: true });

        const selectedVehicle = flota.find(v => v.id === form.selectedVehicle) || {};
        const selectedClient = kontrahenci.find(c => c.id === form.selectedClient) || {};

        doc.setData({
            rentalAmount: form.rentalAmount || '',
            marka: selectedVehicle.marka || '',
            model: selectedVehicle.model || '',
            registrationNumber: selectedVehicle.registrationNumber || '',
            imie: form.imie || '',
            nazwisko: form.nazwisko || '',
            nrDowodu: selectedClient.nrDowodu || '',
            pesel: selectedClient.pesel || '',
            telefon: selectedClient.telefon || '',
            adres: selectedClient.adres || '',
            currentDate: new Date().toLocaleDateString(),
        });

        try {
            doc.render();
            const out = doc.getZip().generate({ type: "blob" });

            const document = {
                nazwa: `Protokół ${operationType === 'wydanie' ? 'wydania' : 'odbioru'}`,
                data: new Date().toISOString().split('T')[0],
                plik: out,
                status: 'Nowy',
                rodzaj: operationType,
            };

            // Dodaj dokument do bazy danych
            const documentId = await addDocumentToDatabase(document);

            if (documentId) {
                // Pobierz zaktualizowaną listę dokumentów
                await fetchDocuments();
            }
        } catch (error) {
            console.error('Błąd podczas generowania pliku:', error);
        }
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        generateDocx();
        setOpen(false);
    };

    const handleDownload = (file, type) => {
        const fileName = type === 'wydanie' ? 'Protokół_wydania' : 'Protokół_odbioru';
        saveAs(file, `${fileName}_${new Date().toISOString()}.docx`);
    };

    const handleDeleteDocument = async (id) => {
        const status = await deleteDocumentFromDatabase(id);
        if (status === 'success') {
            // Pobierz zaktualizowaną listę dokumentów
            await fetchDocuments();
        }
    };

    return (
        <Box className="main-content">
            <Typography variant="h4" gutterBottom>Wydanie samochodu</Typography>
            <Button variant="contained" onClick={() => handleOpenDialog('wydanie')}>Wydaj Samochód</Button>


            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>{operationType === 'wydanie' ? 'Protokół Wydania' : 'Protokół Odbioru'}</DialogTitle>
                <DialogContent>
                    <TextField
                        name="imie"
                        label="Imię"
                        value={form.imie}
                        onChange={handleInputChange}
                        fullWidth
                    />
                    <TextField
                        name="nazwisko"
                        label="Nazwisko"
                        value={form.nazwisko}
                        onChange={handleInputChange}
                        fullWidth
                    />
                    <TextField
                        name="rentalAmount"
                        label="Kwota wynajmu"
                        value={form.rentalAmount}
                        onChange={handleInputChange}
                        fullWidth
                    />
                    <TextField
                        name="fuelLevel"
                        label="Stan paliwa"
                        value={form.fuelLevel}
                        onChange={handleInputChange}
                        fullWidth
                    />
                    <TextField
                        name="vehicleCondition"
                        label="Stan pojazdu"
                        value={form.vehicleCondition}
                        onChange={handleInputChange}
                        fullWidth
                        multiline
                    />
                    <FormControlLabel
                        control={<Checkbox checked={form.spareTire} onChange={handleInputChange} name="spareTire" />}
                        label="Koło zapasowe"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={form.firstAidKit} onChange={handleInputChange} name="firstAidKit" />}
                        label="Apteczka"
                    />
                    <FormControl fullWidth>
                        <InputLabel>Wybierz pojazd</InputLabel>
                        <Select
                            name="selectedVehicle"
                            value={form.selectedVehicle}
                            onChange={handleInputChange}
                        >
                            <MenuItem value=""><em>-- Wybierz pojazd --</em></MenuItem>
                            {Array.isArray(flota) && flota.map(vehicle => (
                                <MenuItem key={vehicle.id} value={vehicle.id}>
                                    {vehicle.model} - {vehicle.vin}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel>Wybierz kontrahenta</InputLabel>
                        <Select
                            name="selectedClient"
                            value={form.selectedClient}
                            onChange={handleInputChange}
                        >
                            <MenuItem value=""><em>-- Wybierz kontrahenta --</em></MenuItem>
                            {Array.isArray(kontrahenci) && kontrahenci.map(client => (
                                <MenuItem key={client.id} value={client.id}>
                                    {client.nazwisko} - {client.pesel}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Anuluj</Button>
                    <Button onClick={handleFormSubmit}>Generuj Dokument</Button>
                </DialogActions>
            </Dialog>

            {documents.length > 0 && (
                <TableContainer component={Paper} sx={{ mt: 3 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Nazwa</TableCell>
                                <TableCell>Data</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Rodzaj</TableCell>
                                <TableCell>Akcje</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {documents.map(doc => (
                                <TableRow key={doc.id}>
                                    <TableCell>{doc.nazwa}</TableCell>
                                    <TableCell>{doc.data}</TableCell>
                                    <TableCell>{doc.status}</TableCell>
                                    <TableCell>{doc.rodzaj}</TableCell>
                                    <TableCell>
                                        <Button onClick={() => handleDownload(doc.plik, doc.rodzaj)}>Pobierz</Button>
                                        <Button onClick={() => handleDeleteDocument(doc.id)}>Usuń</Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Box>
    );
};

export default WydanieOdbiorSamochodu;