import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    MenuItem,
    FormControl,
} from '@mui/material';
import { useConfirmDelete } from './ConfirmDelete';

const HistoriaSerwisowa = () => {
    const [form, setForm] = useState({
        id: '',
        data: '',
        opis: '',
        koszt: '',
        autoId: '',
    });

    const [open, setOpen] = useState(false);
    const [filtersVisible, setFiltersVisible] = useState(false);
    const [historia, setHistoria] = useState([]);
    const [flota, setFlota] = useState([]);
    const [filters, setFilters] = useState({
        opis: '',
        data: '',
        koszt: '',
        autoId: '',
    });

    const { confirmDelete, ConfirmationDialog } = useConfirmDelete();

    useEffect(() => {
        // Fetch historia serwisowa
        fetch('https://autosolgasystem.pl/django-app/dbview/historiaserwis_disp')
            .then((response) => response.json())
            .then((data) => setHistoria(data))
            .catch((error) => console.error('Błąd pobierania historii serwisowej:', error));

        // Fetch flota (cars) data
        fetch('https://autosolgasystem.pl/django-app/dbview/')
            .then((response) => response.json())
            .then((data) => setFlota(data))
            .catch((error) => console.error('Błąd pobierania floty:', error));
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters({ ...filters, [name]: value });
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        const payload = {
            data_zdarzenia: form.data,
            opis: form.opis,
            koszt: form.koszt,
            auto_id: form.auto_id, // zmiana z autoId na auto_id
        };

        if (form.id) {
            fetch(`https://autosolgasystem.pl/django-app/dbview/update_historiaserwis/${form.id}/`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload),
            })
                .then((response) => response.json())
                .then((data) => {
                    setHistoria((prevHistoria) =>
                        prevHistoria.map((item) => (item.id === form.id ? { ...item, ...data } : item))
                    );
                    setForm({ id: '', data: '', opis: '', koszt: '', auto_id: '' }); // zmiana z autoId na auto_id
                    setOpen(false);
                })
                .catch((error) => console.error('Błąd podczas aktualizacji historii serwisowej:', error));
        } else {
            fetch('https://autosolgasystem.pl/django-app/dbview/create_historiaserwis', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload),
            })
                .then((response) => response.json())
                .then((data) => {
                    setHistoria((prevHistoria) => [...prevHistoria, data]);
                    setForm({ data: '', opis: '', koszt: '', auto_id: '' }); // zmiana z autoId na auto_id
                    setOpen(false);
                })
                .catch((error) => console.error('Błąd podczas tworzenia historii serwisowej:', error));
        }
    };


    const handleDeleteClick = async (id) => {
        const confirmed = await confirmDelete('Czy na pewno chcesz usunąć ten wpis?');
        if (confirmed) {
            fetch(`https://autosolgasystem.pl/django-app/dbview/delete_historiaserwis/${id}/`, {
                method: 'DELETE',
            })
                .then(() => {
                    setHistoria((prevHistoria) => prevHistoria.filter((item) => item.id !== id));
                })
                .catch((error) => console.error('Błąd podczas usuwania wpisu:', error));
        }
    };

    const handleEditClick = (item) => {
        setForm(item);
        setOpen(true);
    };

    const filteredHistoria = historia.filter((item) => {
        return (
            (!filters.opis || item.opis.toLowerCase().includes(filters.opis.toLowerCase())) &&
            (!filters.data || item.data === filters.data) &&
            (!filters.koszt || item.koszt === parseFloat(filters.koszt)) &&
            (!filters.autoId || item.autoId === filters.autoId)
        );
    });

    return (
        <Box className="main-content">
            <Typography variant="h4" gutterBottom>
                Historia Serwisowa
            </Typography>
            <Box className="button-group">
                <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
                    Dodaj Wpis
                </Button>
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => setFiltersVisible(!filtersVisible)}
                >
                    Filtruj
                </Button>
            </Box>

            {filtersVisible && (
                <Box className="filter-box" mb={2} display="flex" gap={2} flexWrap="wrap" mt={2}>
                    <FormControl fullWidth>
                        <TextField
                            label="Filtruj po opisie"
                            name="opis"
                            value={filters.opis}
                            onChange={handleFilterChange}
                            placeholder="Opis"
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <TextField
                            label="Filtruj po dacie"
                            type="date"
                            name="data"
                            value={filters.data}
                            onChange={handleFilterChange}
                            InputLabelProps={{ shrink: true }}
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <TextField
                            label="Filtruj po koszcie"
                            type="number"
                            name="koszt"
                            value={filters.koszt}
                            onChange={handleFilterChange}
                            placeholder="Koszt"
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <TextField
                            select
                            label="Filtruj po pojeździe"
                            name="autoId"
                            value={filters.autoId}
                            onChange={handleFilterChange}
                        >
                            <MenuItem value="">
                                <em>Wszystkie</em>
                            </MenuItem>
                            {flota.map((auto) => (
                                <MenuItem key={auto.id} value={auto.id}>
                                    {auto.marka} - {auto.model}
                                </MenuItem>
                            ))}
                        </TextField>
                    </FormControl>
                </Box>
            )}

            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>{form.id ? 'Edytuj Wpis' : 'Dodaj Wpis'}</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleFormSubmit}>
                        <TextField
                            name="data"
                            label="Data"
                            type="date"
                            value={form.data || ''}
                            onChange={handleInputChange}
                            required
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                        />
                        <TextField
                            name="opis"
                            label="Opis"
                            value={form.opis || ''}
                            onChange={handleInputChange}
                            required
                            fullWidth
                        />
                        <TextField
                            name="koszt"
                            label="Koszt"
                            type="number"
                            value={form.koszt || ''}
                            onChange={handleInputChange}
                            required
                            fullWidth
                        />
                        <TextField
                            select
                            name="auto_id" // zmiana z autoId na auto_id
                            label="auto"
                            value={form.auto_id || ''} // zmiana z autoId na auto_id
                            onChange={handleInputChange}
                            required
                            fullWidth
                        >
                            {flota.map((auto) => (
                                <MenuItem key={auto.id} value={auto.id}>
                                    {auto.marka} - {auto.model}
                                </MenuItem>
                            ))}
                        </TextField>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="secondary">
                        Anuluj
                    </Button>
                    <Button onClick={handleFormSubmit} color="primary">
                        {form.id ? 'Zapisz' : 'Dodaj'}
                    </Button>
                </DialogActions>
            </Dialog>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Data</TableCell>
                            <TableCell>Opis</TableCell>
                            <TableCell>Koszt</TableCell>
                            <TableCell>auto</TableCell>
                            <TableCell>Akcje</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredHistoria.map((item) => (
                            <TableRow key={item.id}>
                                <TableCell>{item.data_zdarzenia}</TableCell>
                                <TableCell>{item.opis}</TableCell>
                                <TableCell>{item.koszt}</TableCell>
                                <TableCell>
                                    {flota.find((auto) => auto.id === item.auto_id)?.marka || 'Brak'} -{' '}
                                    {flota.find((auto) => auto.id === item.auto_id)?.model || 'Brak'}
                                </TableCell>
                                <TableCell>
                                    <Button onClick={() => handleEditClick(item)} color="primary">
                                        Edytuj
                                    </Button>
                                    <Button onClick={() => handleDeleteClick(item.id)} color="secondary">
                                        Usuń
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <ConfirmationDialog />
        </Box>
    );
};

export default HistoriaSerwisowa;
