import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Grid,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    Collapse,
} from '@mui/material';

const Faktury = () => {
    const [form, setForm] = useState({
        id: '',
        data: '',
        kwota: '',
        status: '',
        metoda_platnosci: '',
        rezerwacja_id: '',  // Make sure to use this to reference the correct rezerwacja
    });
    const [open, setOpen] = useState(false);
    const [faktury, setFaktury] = useState([]);
    const [rezerwacje, setRezerwacje] = useState([]);
    const [auta, setAuta] = useState([]);
    const [firmy, setFirmy] = useState([]);
    const [osobyPrywatne, setOsobyPrywatne] = useState([]);
    const [openFilters, setOpenFilters] = useState(false); // State for managing filter visibility
    const [filters, setFilters] = useState({
        status: '',
        rezerwacja_id: '',
    });
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);

    useEffect(() => {
        // Pobierz rezerwacje
        fetch('https://autosolgasystem.pl/django-app/dbview/rezerwacja_disp')
            .then((response) => response.json())
            .then((data) => setRezerwacje(data))
            .catch((error) => console.error('Error fetching rezerwacje:', error));

        // Pobierz auta
        fetch('https://autosolgasystem.pl/django-app/dbview/')
            .then((response) => response.json())
            .then((data) => setAuta(data))
            .catch((error) => console.error('Error fetching auta:', error));

        // Pobierz firmy
        fetch('https://autosolgasystem.pl/django-app/dbview/firma_disp')
            .then((response) => response.json())
            .then((data) => setFirmy(data))
            .catch((error) => console.error('Error fetching firmy:', error));

        // Pobierz osoby prywatne
        fetch('https://autosolgasystem.pl/django-app/dbview/osoba_disp')
            .then((response) => response.json())
            .then((data) => setOsobyPrywatne(data))
            .catch((error) => console.error('Error fetching osoby prywatne:', error));

        // Fetching Faktury
        fetch('https://autosolgasystem.pl/django-app/dbview/faktura_disp')
            .then((response) => response.json())
            .then((data) => setFaktury(data))
            .catch((error) => console.error('Error fetching faktury:', error));
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters({ ...filters, [name]: value });
    };

    const rezerwacjeZSzczegolami = rezerwacje.map((rezerwacja) => {
        const auto = auta.find((a) => a.id === rezerwacja.auto_id);
        const firma = firmy.find((f) => f.id === rezerwacja.firma_id);
        const osobaPrywatna = osobyPrywatne.find((o) => o.id === rezerwacja.osoba_prywatna_id);

        return {
            ...rezerwacja,
            auto,
            firma,
            osobaPrywatna,
        };
    });

    const handleFormSubmit = (e) => {
        e.preventDefault();
        const payload = {
            data: form.data,
            kwota: form.kwota,
            status: form.status,
            metoda_platnosci: form.metoda_platnosci,
            rezerwacja_id: form.rezerwacja_id, // Correct reference for rezerwacja_id
        };

        if (form.id) {
            // Update the Faktura
            fetch(`https://autosolgasystem.pl/django-app/dbview/update_faktura/${form.id}/`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload),
            })
                .then((response) => response.json())
                .then((data) => {
                    setFaktury((prevFaktury) =>
                        prevFaktury.map((faktura) =>
                            faktura.id === form.id ? { ...faktura, ...data } : faktura
                        )
                    );
                    resetForm();
                })
                .catch((error) => console.error('Error updating faktura:', error));
        } else {
            // Create a new Faktura
            fetch('https://autosolgasystem.pl/django-app/dbview/create_faktura', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload),
            })
                .then((response) => response.json())
                .then((data) => {
                    setFaktury((prevFaktury) => [...prevFaktury, data]);
                    resetForm();
                })
                .catch((error) => console.error('Error creating faktura:', error));
        }
    };

    const resetForm = () => {
        setForm({
            data: '',
            kwota: '',
            status: '',
            metoda_platnosci: '',
            rezerwacja_id: '',  // Reset rezerwacja_id as well
        });
        setOpen(false);
    };

    const handleDeleteClick = (id) => {
        setSelectedInvoiceId(id);
        setOpenConfirmDialog(true);
    };

    const handleConfirmDelete = () => {
        if (selectedInvoiceId !== null) {
            fetch(`https://autosolgasystem.pl/django-app/dbview/delete_faktura/${selectedInvoiceId}/`, {
                method: 'DELETE',
            })
                .then(() => {
                    setFaktury((prevFaktury) =>
                        prevFaktury.filter((faktura) => faktura.id !== selectedInvoiceId)
                    );
                })
                .catch((error) => console.error('Error deleting faktura:', error));
        }
        setOpenConfirmDialog(false);
    };

    const handleCancelDelete = () => {
        setOpenConfirmDialog(false);
    };

    const handleEditClick = (faktura) => {
        setForm({
            id: faktura.id,
            data: faktura.data,
            kwota: faktura.kwota,
            status: faktura.status,
            metoda_platnosci: faktura.metoda_platnosci,
            rezerwacja_id: faktura.rezerwacja_id, // Correct reference to rezerwacja_id
        });
        setOpen(true);
    };

    const filteredFaktury = faktury.filter((faktura) => {
        const matchesStatus = filters.status === '' || faktura.status.toLowerCase().includes(filters.status.toLowerCase());
        const matchesRezerwacja = filters.rezerwacja_id === '' || faktura.rezerwacja_id === filters.rezerwacja_id;
        return matchesStatus && matchesRezerwacja;
    });

    return (
        <Box className="main-content">
            <Typography variant="h4" gutterBottom>
                Rozliczenia
            </Typography>
            <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
                Dodaj Fakturę
            </Button>
            <Button
                variant="outlined"
                color="secondary"
                onClick={() => setOpenFilters(!openFilters)}  // Toggle filters visibility
            >
                Filtruj
            </Button>

            {/* Filters */}
            <Collapse in={openFilters} timeout="auto" unmountOnExit>
                <Box sx={{ marginTop: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel>Status Rozliczenia</InputLabel>
                                <Select
                                    name="status"
                                    value={filters.status}
                                    onChange={handleFilterChange}
                                    fullWidth
                                >
                                    <MenuItem value="">Wszystkie</MenuItem>
                                    <MenuItem value="paid">Opłacona</MenuItem>
                                    <MenuItem value="unpaid">Nieopłacona</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel>Rezerwacja</InputLabel>
                                <Select
                                    name="rezerwacja_id"
                                    value={filters.rezerwacja_id}
                                    onChange={handleFilterChange}
                                    fullWidth
                                >
                                    <MenuItem value="">Wszystkie</MenuItem>
                                    {rezerwacje.map((rezerwacja) => (
                                        <MenuItem key={rezerwacja.id} value={rezerwacja.id}>
                                            {rezerwacja.odKiedy} - {rezerwacja.doKiedy}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
            </Collapse>

            <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Data rozliczenia</TableCell>
                            <TableCell>Kwota</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Metoda Płatności</TableCell>
                            <TableCell>Rezerwacja</TableCell>
                            <TableCell>Akcje</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredFaktury.map((faktura) => {
                            const rezerwacja = rezerwacje.find((r) => r.id === faktura.rezerwacja_id);
                            return (
                                <TableRow key={faktura.id}>
                                    <TableCell>{faktura.data}</TableCell>
                                    <TableCell>{faktura.kwota}</TableCell>
                                    <TableCell>{faktura.status === 'paid' ? 'Opłacona' : 'Nieopłacona'}</TableCell>
                                    <TableCell>
                                        {faktura.metoda_platnosci === 'card' ? 'Karta' :
                                            faktura.metoda_platnosci === 'cash' ? 'Gotówka' : 'Przelew'}
                                    </TableCell>
                                    <TableCell>{rezerwacja?.odKiedy || 'Brak'}</TableCell>
                                    <TableCell>
                                        <Button onClick={() => handleEditClick(faktura)} color="primary">
                                            Edytuj
                                        </Button>
                                        <Button onClick={() => handleDeleteClick(faktura.id)} color="secondary">
                                            Usuń
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
                <DialogTitle>{form.id ? 'Edytuj Fakturę' : 'Dodaj Fakturę'}</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleFormSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    name="data"
                                    label="Data Rozliczenia"
                                    type="date"
                                    value={form.data}
                                    onChange={handleInputChange}
                                    required
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="kwota"
                                    label="Kwota"
                                    type="number"
                                    value={form.kwota}
                                    onChange={handleInputChange}
                                    required
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel>Status Rozliczenia</InputLabel>
                                    <Select
                                        name="status"
                                        value={form.status}
                                        onChange={handleInputChange}
                                        required
                                    >
                                        <MenuItem value="paid">Opłacona</MenuItem>
                                        <MenuItem value="unpaid">Nieopłacona</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel>Metoda Płatności</InputLabel>
                                    <Select
                                        name="metoda_platnosci"
                                        value={form.metoda_platnosci}
                                        onChange={handleInputChange}
                                        required
                                    >
                                        <MenuItem value="card">Karta</MenuItem>
                                        <MenuItem value="cash">Gotówka</MenuItem>
                                        <MenuItem value="transfer">Przelew</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel>Rezerwacja</InputLabel>
                                    <Select
                                        name="rezerwacja_id"
                                        value={filters.rezerwacja_id}
                                        onChange={handleFilterChange}
                                        fullWidth
                                    >
                                        <MenuItem value="">Wszystkie</MenuItem>
                                        {rezerwacjeZSzczegolami.map((rezerwacja) => (
                                            <MenuItem key={rezerwacja.id} value={rezerwacja.id}>
                                                {/* Wyświetl szczegóły auta */}
                                                {rezerwacja.auto && (
                                                    <span>
                                                        {rezerwacja.auto.marka} {rezerwacja.auto.model}
                                                    </span>
                                                )}
                                                {/* Wyświetl szczegóły firmy lub osoby prywatnej */}
                                                {rezerwacja.firma && (
                                                    <span>
                                                        - {rezerwacja.firma.nazwa}
                                                    </span>
                                                )}
                                                {rezerwacja.osobaPrywatna && (
                                                    <span>
                                                        - {rezerwacja.osobaPrywatna.imie} {rezerwacja.osobaPrywatna.nazwisko}
                                                    </span>
                                                )}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="secondary">
                        Anuluj
                    </Button>
                    <Button onClick={handleFormSubmit} color="primary">
                        {form.id ? 'Zapisz' : 'Dodaj'}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Dialog do potwierdzenia usunięcia faktury */}
            <Dialog open={openConfirmDialog} onClose={handleCancelDelete}>
                <DialogTitle>Potwierdzenie usunięcia</DialogTitle>
                <DialogContent>
                    <Typography>Jesteś pewny, że chcesz usunąć tę fakturę?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelDelete} color="secondary">
                        Anuluj
                    </Button>
                    <Button onClick={handleConfirmDelete} color="primary">
                        Usuń
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Faktury;
