import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Select,
    MenuItem
} from '@mui/material';
import './styles.css';
import { useConfirmDelete } from './ConfirmDelete';

const Magazyn = () => {
    const [magazyn, setMagazyn] = useState([]);
    const [flota, setFlota] = useState([]);
    const [form, setForm] = useState({
        typ: '',
        opis: '',
        auto: '',
        akcje: '',
    });
    const [open, setOpen] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [editId, setEditId] = useState(null);

    const [filter, setFilter] = useState({
        typ: '',
        auto: '',
        opis: '',
    });
    const [filtersVisible, setFiltersVisible] = useState(false);
    const [customTypes, setCustomTypes] = useState([]);

    const { confirmDelete, ConfirmationDialog } = useConfirmDelete();

    const typyMagazynowe = [
        'filtr oleju', 'filtr paliwa', 'filtr powietrza', 'filtr kabinowy',
        'opony zimowe', 'opony letnie', 'smary i oleje', 'alternatory',
        'paski', 'rozrządy', 'tarcze', 'klocki przod', 'klocki tyl',
        'amortyzatory', 'sprężyny', 'przeguby', 'drążki', ...customTypes
    ];

    useEffect(() => {
        fetchMagazyn();
        fetchFlota();
    }, []);

    const fetchMagazyn = async () => {
        try {
            const response = await fetch('https://autosolgasystem.pl/django-app/dbview/magazyn_disp/');
            const data = await response.json();
            setMagazyn(data);
        } catch (error) {
            console.error('Błąd podczas pobierania magazynu:', error);
        }
    };

    const fetchFlota = async () => {
        try {
            const response = await fetch('https://autosolgasystem.pl/django-app/dbview/');
            const data = await response.json();
            setFlota(data);
        } catch (error) {
            console.error('Błąd podczas pobierania floty:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilter({ ...filter, [name]: value });
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        const payload = {
            id: form.id || undefined, // Dodane id
            typ: form.typ,
            opis: form.opis,
            auto_id: form.auto_id,
            akcje: form.akcje || null,
            data: form.data,
        };

        if (editMode && form.id) {
            // Aktualizacja istniejącego rekordu (PUT)
            fetch(`https://autosolgasystem.pl/django-app/dbview/update_magazyn/${form.id}/`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload),
            })
                .then((response) => response.json())
                .then((data) => {
                    setMagazyn((prevMagazyn) =>
                        prevMagazyn.map((item) => (item.id === form.id ? { ...item, ...data } : item))
                    );
                    setForm({ id: '', typ: '', opis: '', auto: '', akcje: '', data: '' });
                    setOpen(false);
                })
                .catch((error) => console.error('Błąd podczas aktualizacji magazynu:', error));
        } else {
            // Tworzenie nowego rekordu (POST)
            fetch('https://autosolgasystem.pl/django-app/dbview/create_magazyn', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload),
            })
                .then((response) => response.json())
                .then((data) => {
                    setMagazyn((prevMagazyn) => [...prevMagazyn, data]);
                    setForm({ typ: '', opis: '', auto: '', akcje: '', data: '' });
                    setOpen(false);
                })
                .catch((error) => console.error('Błąd podczas tworzenia magazynu:', error));
        }
    };



    const handleEditClick = (item) => {
        setForm({
            id: item.id,
            typ: item.typ,
            opis: item.opis,
            auto: flota.find((auto) => auto.id === item.auto_id)?.model || '',
            auto_id: item.auto_id,
            pojazd_id: item.auto_id, // Dodane pole pojazd_id
            akcje: item.akcje,
            data: item.data || '',
        });
        setEditMode(true);
        setOpen(true);
    };


    const handleDeleteClick = async (id) => {
        const confirmed = await confirmDelete('Czy na pewno chcesz usunąć ten rekord?');
        if (confirmed) {
            try {
                await fetch(`https://autosolgasystem.pl/django-app/dbview/delete_magazyn/${id}/`, {
                    method: 'DELETE',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ pojazd_id: form.auto_id }) // Przesyłamy pojazd_id, jeśli API tego wymaga
                });
                fetchMagazyn();
            } catch (error) {
                console.error('Błąd podczas usuwania danych:', error);
            }
        }
    };


    const resetForm = () => {
        setForm({ typ: '', opis: '', auto: '', akcje: '' });
        setEditMode(false);
        setEditId(null);
        setOpen(false);
    };

    const addCustomType = () => {
        const newType = prompt('Wprowadź nowy typ:');
        if (newType && !typyMagazynowe.includes(newType)) {
            setCustomTypes([...customTypes, newType]);
        }
    };

    const filteredMagazyn = magazyn.filter(item =>
        (filter.typ === '' || item.typ === filter.typ) &&
        (filter.auto === '' || item.auto === filter.auto) &&
        (filter.opis === '' || item.opis.toLowerCase().includes(filter.opis.toLowerCase()))
    );

    return (
        <Box className="main-content">
            <Typography variant="h4" gutterBottom>
                Magazyn
            </Typography>
            <Box className="button-group">
                <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
                    Dodaj
                </Button>
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => setFiltersVisible(!filtersVisible)}
                >
                    Filtruj
                </Button>
            </Box>

            {filtersVisible && (
                <Box className="filter-container">
                    <Select
                        name="typ"
                        value={filter.typ}
                        onChange={handleFilterChange}
                        displayEmpty
                        className="filter-field"
                    >
                        <MenuItem value="">Wszystkie Typy</MenuItem>
                        {typyMagazynowe.map((typ, index) => (
                            <MenuItem key={index} value={typ}>{typ}</MenuItem>
                        ))}
                    </Select>
                    <Select
                        name="auto"
                        value={filter.auto}
                        onChange={handleFilterChange}
                        displayEmpty
                        className="filter-field"
                    >
                        <MenuItem value="">Wszystkie Auta</MenuItem>
                        {flota.map((auto) => (
                            <MenuItem key={auto.id} value={auto.model}>
                                {auto.model} ({auto.marka})
                            </MenuItem>
                        ))}
                    </Select>
                    <TextField
                        name="opis"
                        label="Opis"
                        value={filter.opis}
                        onChange={handleFilterChange}
                        className="filter-field"
                    />
                </Box>
            )}

            <Dialog open={open} onClose={resetForm}>
                <DialogTitle>{editMode ? 'Edytuj' : 'Dodaj do Magazynu'}</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleFormSubmit} className="form-container">
                        <Select
                            name="typ"
                            value={form.typ}
                            onChange={handleInputChange}
                            displayEmpty
                            required
                            className="textfield"
                        >
                            <MenuItem value="" disabled>
                                Wybierz Typ
                            </MenuItem>
                            {typyMagazynowe.map((typ, index) => (
                                <MenuItem key={index} value={typ}>{typ}</MenuItem>
                            ))}
                            <MenuItem onClick={addCustomType} value="Dodaj typ">
                                Dodaj typ
                            </MenuItem>
                        </Select>
                        <TextField
                            name="data"
                            label="Data"
                            type="date"
                            value={form.data || ''} // Dodano domyślną wartość pustą
                            onChange={handleInputChange}
                            required
                            className="textfield"
                        />

                        <TextField
                            name="opis"
                            label="Opis"
                            value={form.opis}
                            onChange={handleInputChange}
                            required
                            multiline
                            rows={4}
                            className="textfield"
                        />
                        <Select
                            name="auto"
                            value={form.auto}
                            onChange={(e) => {
                                const selectedAuto = e.target.value;
                                const selectedAutoId = flota.find((item) => item.model === selectedAuto)?.id || '';
                                setForm({
                                    ...form,
                                    auto: selectedAuto,
                                    auto_id: selectedAutoId,
                                    pojazd_id: selectedAutoId, // Przypisanie pojazd_id
                                });
                            }}
                            displayEmpty
                            required
                            className="textfield"
                        >
                            <MenuItem value="" disabled>
                                Wybierz Auto
                            </MenuItem>
                            {flota.map((auto) => (
                                <MenuItem key={auto.id} value={auto.model}>
                                    {auto.model} ({auto.marka})
                                </MenuItem>
                            ))}
                        </Select>

                        {form.typ === 'uszkodzenia' && (
                            <Select
                                name="akcje"
                                value={form.akcje}
                                onChange={handleInputChange}
                                displayEmpty
                                required
                                className="textfield"
                            >
                                <MenuItem value="" disabled>
                                    Wybierz Akcję
                                </MenuItem>
                                <MenuItem value="naprawione">Naprawione</MenuItem>
                                <MenuItem value="nienaprawione">Nienaprawione</MenuItem>
                            </Select>
                        )}
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={resetForm} color="secondary">
                        Anuluj
                    </Button>
                    <Button onClick={handleFormSubmit} color="primary">
                        {editMode ? 'Zapisz zmiany' : 'Dodaj'}
                    </Button>
                </DialogActions>
            </Dialog>

            <TableContainer component={Paper} className="table-container">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Data</TableCell>
                            <TableCell>Typ</TableCell>
                            <TableCell>Opis</TableCell>
                            <TableCell>Auto</TableCell>
                            <TableCell>Opcje</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredMagazyn.map((item) => (
                            <TableRow key={item.id} className="table-row">
                                <TableCell>{item.data}</TableCell>
                                <TableCell>{item.typ}</TableCell>
                                <TableCell>{item.opis}</TableCell>
                                <TableCell>
                                    {flota.find((auto) => auto.id === item.auto_id)?.marka || 'Brak'} -{' '}
                                    {flota.find((auto) => auto.id === item.auto_id)?.model || 'Brak'}
                                </TableCell>
                                <TableCell>
                                    <Button onClick={() => handleEditClick(item)} color="primary">Edytuj</Button>
                                    <Button onClick={() => handleDeleteClick(item.id)} color="secondary">Usuń</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <ConfirmationDialog />
        </Box>
    );
};

export default Magazyn;
