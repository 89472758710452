import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material';
import { useConfirmDelete } from './ConfirmDelete';
import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import { saveAs } from 'file-saver';

const UmowaNajmuPrivArchiwum = () => {
    const [data, setData] = useState({
        documents: [],
        archivedDocuments: [],
        flota: [],
        osobyPrywatne: [],
    });
    const [form, setForm] = useState({
        nazwa: '', data: '', godzina: '', dzien: '', dogodziny: '', dodzien: '', dodatkowe: '', selectedVehicle: '', selectedClient: ''
    });
    const [open, setOpen] = useState(false);
    const { confirmDelete, ConfirmationDialog } = useConfirmDelete();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [docRes, flotaRes, osobyRes] = await Promise.all([
                    fetch('https://autosolgasystem.pl/django-app/dbview/dokumenty/'),
                    fetch('https://autosolgasystem.pl/django-app/dbview/'),
                    fetch('https://autosolgasystem.pl/django-app/dbview/osoba_disp')
                ]);
                const [docData, flotaData, osobyData] = await Promise.all([
                    docRes.json(), flotaRes.json(), osobyRes.json()
                ]);
                setData({
                    documents: docData.filter((doc) => doc.rodzaj === 'umowa najmu priv' && !doc.archived),
                    archivedDocuments: docData.filter((doc) => doc.rodzaj === 'umowa najmu priv' && doc.status=== 'zarchiwizowany'),
                    flota: flotaData,
                    osobyPrywatne: osobyData
                });
            } catch (error) {
                console.error('Błąd pobierania danych:', error);
            }
        };
        fetchData();
    }, []);

    const handleArchiveClick = (id) => {
        setData((prev) => {
            const updatedDocs = prev.documents.map((doc) =>
                doc.id === id ? { ...doc, archived: true } : doc
            );
            return {
                ...prev,
                documents: updatedDocs.filter(doc => !doc.archived),
                archivedDocuments: [...prev.archivedDocuments, ...updatedDocs.filter(doc => doc.archived)],
            };
        });
    };

    return (
        <Box className="main-content">
            <Typography variant="h4" gutterBottom>Archiwalne Umowy Prywatne</Typography>
            <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Nazwa  pliku</TableCell>
                            <TableCell>Data archiwizacji</TableCell>
                            <TableCell>Akcje</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.archivedDocuments.map((doc) => (
                            <TableRow key={doc.id}>
                                <TableCell>{doc.nazwa}</TableCell>
                                <TableCell>{doc.data}</TableCell>
                                <TableCell>
                                    <Button onClick={() => window.open(doc.plik, '_blank')} color="primary">Pobierz</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default UmowaNajmuPrivArchiwum;
