import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@mui/material';
import { saveAs } from 'file-saver';

const ArchiwalneUmowyFirmowe = () => {
    const [archiwalneUmowyFirmowe, setArchiwalneUmowyFirmowe] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch('https://autosolgasystem.pl/django-app/dbview/dokumenty/')
            .then((response) => response.json())
            .then((data) => {
                const filteredDocuments = data.filter((doc) => doc.rodzaj === 'umowa najmu firma' && doc.status=== 'zarchiwizowany');
                const formattedDocuments = filteredDocuments.map((doc) => ({
                    id: doc.id,
                    fileName: doc.nazwa,
                    dateArchived: doc.data,
                    clientName: doc.firmaNazwa || 'Nieznany',
                    file: doc.plik,
                }));
                setArchiwalneUmowyFirmowe(formattedDocuments);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Błąd pobierania dokumentów:', error);
                setError('Wystąpił błąd podczas pobierania danych.');
                setIsLoading(false);
            });
    }, []);

    const handleDownload = (file, fileName) => {
        saveAs(file, fileName || 'Archiwalna_umowa.docx');
    };

    if (isLoading) {
        return <p>Ładowanie danych...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <Box className="main-content">
            <Typography variant="h4" gutterBottom>Archiwalne Umowy Firmowe</Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Nazwa pliku</TableCell>
                            <TableCell>Data Archiwizacji</TableCell>
                            <TableCell>Akcje</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {archiwalneUmowyFirmowe.map((umowa) => (
                            <TableRow key={umowa.id}>
                                <TableCell>{umowa.fileName || 'Umowa_bez_nazwy.docx'}</TableCell>
                                <TableCell>{umowa.dateArchived}</TableCell>
                                <TableCell>
                                    <Button onClick={() => window.open(umowa.plik, '_blank')} color="primary">Pobierz</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default ArchiwalneUmowyFirmowe;