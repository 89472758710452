import React, { useState, useEffect } from 'react';
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle,
    TextField, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Paper, Typography, FormControlLabel, Checkbox,
    Collapse, Box, Grid
} from '@mui/material';
import { useConfirmDelete, ConfirmationDialog } from './ConfirmDelete';

const Flota = () => {
    const [flota, setFlota] = useState([]);
    const [form, setForm] = useState({
        id: '',
        marka: '',
        model: '',
        vin: '',
        kwoty_wynajmu: '',
        cena_kupna: '',
        stan_licznika: '',
        stan_paliwa: '',
        stan_pojazdu: '',
        akcesoria: '',
        oc: false,
        ac: false,
        nw: false,
        numer_polisy: '',
        dane_ubezpieczyciela: ''
    });
    const [open, setOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [confirmZlomowanieOpen, setConfirmZlomowanieOpen] = useState(false);
    const [selectedPojazdId, setSelectedPojazdId] = useState(null);

    const [filterOptions, setFilterOptions] = useState({
        marka: '',
        model: '',
        vin: '',
        kwoty_wynajmu: '',
        cena_kupna: '',
        oc: false,
        ac: false,
        nw: false
    });
    const [filtersVisible, setFiltersVisible] = useState(false);

    const { confirmDelete, ConfirmationDialog } = useConfirmDelete();

    useEffect(() => {
        fetch('https://autosolgasystem.pl/django-app/dbview/')
            .then((response) => response.json())
            .then((data) => setFlota(data))
            .catch((error) => console.error('Błąd pobierania danych:', error));
    }, []);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setForm({ ...form, [name]: type === 'checkbox' ? checked : value });
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();

        const payload = {
            id: Date.now(),
            marka: form.marka,
            model: form.model,
            vin: form.vin,
            kwoty_wynajmu: parseFloat(form.kwoty_wynajmu),
            cena_kupna: parseFloat(form.cena_kupna),
            stan_licznika: parseInt(form.stan_licznika, 10),
            stan_paliwa: parseFloat(form.stan_paliwa),
            stan_pojazdu: form.stan_pojazdu || "uszkodzony",
            akcesoria: form.akcesoria || "brak",
            oc: form.oc || false,
            ac: form.ac || false,
            nw: form.nw || false,
            numer_polisy: form.numer_polisy || "",
            dane_ubezpieczyciela: form.dane_ubezpieczyciela || ""
        };

        console.log('Wysyłane dane:', payload);

        fetch("https://autosolgasystem.pl/django-app/dbview/create_auto", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`Błąd serwera: ${response.status}`);
                }
                return response.json();
            })
            .then((data) => {
                console.log("Rekord dodany:", data);
                setFlota((prevFlota) => [...prevFlota, data]);
                resetForm();
            })
            .catch((error) => {
                console.error("Błąd podczas dodawania rekordu:", error);
                alert("Nie udało się dodać rekordu. Sprawdź szczegóły w konsoli.");
            });
    };

    const resetForm = () => {
        setForm({
            id: '',
            nr_rejestracyjny: '',
            marka: '',
            model: '',
            vin: '',
            kwoty_wynajmu: '',
            cena_kupna: '',
            stan_licznika: '',
            stan_paliwa: '',
            stan_pojazdu: '',
            akcesoria: '',
            oc: false,
            ac: false,
            nw: false,
            numer_polisy: '',
            dane_ubezpieczyciela: ''
        });
        setOpen(false);
    };

    const filteredFlota = flota.filter(pojazd => {
        return (
            pojazd.status === '' &&
            (pojazd.model?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                pojazd.marka?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                pojazd.vin?.toLowerCase().includes(searchTerm.toLowerCase())) &&

            (pojazd.marka?.toLowerCase().includes(filterOptions.marka?.toLowerCase() || '') &&
                pojazd.model?.toLowerCase().includes(filterOptions.model?.toLowerCase() || '') &&
                pojazd.vin?.toLowerCase().includes(filterOptions.vin?.toLowerCase() || '') &&
                (pojazd.kwoty_wynajmu?.toString().includes(filterOptions.kwoty_wynajmu || '')) &&
                (pojazd.cena_kupna?.toString().includes(filterOptions.cena_kupna || '')) &&
                (filterOptions.oc ? pojazd.oc : true) &&
                (filterOptions.ac ? pojazd.ac : true) &&
                (filterOptions.nw ? pojazd.nw : true)
            )
        );
    });

    const handleUpdate = (updatedPojazd) => {
        fetch(`https://autosolgasystem.pl/django-app/dbview/update_auto/${updatedPojazd.id}/`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(updatedPojazd),
        })

            .then((response) => {
                if (!response.ok) throw new Error(`Błąd serwera: ${response.status}`);
                return response.json();
            })
            .then((data) => {
                setFlota((prevFlota) =>
                    prevFlota.map((pojazd) => (pojazd.id === updatedPojazd.id ? { ...data } : pojazd))
                );
                console.log('Zaktualizowano pojazd:', data);
            })
            .catch((error) => {
                console.error('Błąd aktualizacji pojazdu:', error);
                alert('Nie udało się zaktualizować pojazdu.');
            });
    };

    const handleDelete = (id) => {
        if (window.confirm("Czy na pewno chcesz usunąć ten pojazd?")) {
            fetch(`https://autosolgasystem.pl/django-app/dbview/delete_auto/${id}/`, {
                method: 'DELETE',
            })
                .then((response) => {
                    if (response.ok) {
                        setFlota(flota.filter((pojazd) => pojazd.id !== id));
                    } else {
                        throw new Error("Nie udało się usunąć pojazdu.");
                    }
                })
                .catch((error) => console.error('Błąd usuwania pojazdu:', error));
        }
    };

    const confirmZlomowanie = () => {
        handleZlomowanie(selectedPojazdId);
        setConfirmZlomowanieOpen(false);
    };

    const handleZlomowanie = (id) => {
        setSelectedPojazdId(id); // Przechowaj ID pojazdu do złomowania
        setConfirmZlomowanieOpen(true); // Otwórz dialog
        const updatedStatus = { status: 'zezlomowany' };

        fetch(`https://autosolgasystem.pl/django-app/dbview/update_auto/${id}/`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(updatedStatus),
        })
            .then((response) => {
                if (!response.ok) throw new Error(`Błąd serwera: ${response.status}`);
                return response.json();
            })
            .then((updatedData) => {
                setFlota((prevFlota) =>
                    prevFlota.map((pojazd) =>
                        pojazd.id === id ? { ...pojazd, stan_pojazdu: 'złomowany' } : pojazd
                    )
                );
                console.log('Pojazd zezłomowany:', updatedData);
            })
            .catch((error) => {
                console.error('Błąd złomowania pojazdu:', error);
                alert('Nie udało się zezłomować pojazdu.');
            });
    };

    const handleSprzedaz = (id, cena) => {
        setFlota((prevFlota) => prevFlota.map(pojazd =>
            pojazd.id === id ? { ...pojazd, cena_sprzedazy: cena, stan_pojazdu: 'sprzedany' } : pojazd
        ));
    };

    const [editMode, setEditMode] = useState(false);

    const handleEdit = (pojazd) => {
        setForm({ ...pojazd });
        setEditMode(true);
        setOpen(true);
    };

    const handleUpdateSubmit = (e) => {
        e.preventDefault();

        fetch(`https://autosolgasystem.pl/django-app/dbview/update_auto/${form.id}/`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(form),
        })
            .then((response) => {
                if (!response.ok) throw new Error('Błąd aktualizacji');
                return response.json();
            })
            .then((updatedData) => {
                setFlota((prevFlota) =>
                    prevFlota.map((pojazd) => (pojazd.id === form.id ? updatedData : pojazd))
                );
                resetForm();
            })
            .catch((error) => {
                console.error('Błąd aktualizacji:', error);
                alert('Nie udało się zaktualizować pojazdu.');
            });
    };

    return (
        <Box className="main-content">

            <Typography variant="h4" gutterBottom>Flota</Typography>


            <Box className="button-group">
                <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
                    Dodaj Pojazd
                </Button>
                <Button variant="outlined" color="secondary" onClick={() => setFiltersVisible(!filtersVisible)}>
                    {filtersVisible ? 'Ukryj Filtry' : 'Pokaż Filtry'}
                </Button>
            </Box>


            <Collapse in={filtersVisible}>
                <Box marginBottom={2} padding={2} border={1} borderColor="grey.300">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                label="Numer rejestacyjny"
                                variant="outlined"
                                name="nr_rejestracyjny"
                                value={filterOptions.nr_rejestracyjny}
                                onChange={(e) => setFilterOptions({ ...filterOptions, nr_rejestracyjny: e.target.value })}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                label="Marka"
                                variant="outlined"
                                name="marka"
                                value={filterOptions.marka}
                                onChange={(e) => setFilterOptions({ ...filterOptions, marka: e.target.value })}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                label="Model"
                                variant="outlined"
                                name="model"
                                value={filterOptions.model}
                                onChange={(e) => setFilterOptions({ ...filterOptions, model: e.target.value })}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                label="VIN"
                                variant="outlined"
                                name="vin"
                                value={filterOptions.vin}
                                onChange={(e) => setFilterOptions({ ...filterOptions, vin: e.target.value })}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                label="Cena Kupna"
                                variant="outlined"
                                name="cena_kupna"
                                value={filterOptions.cena_kupna}
                                onChange={(e) => setFilterOptions({ ...filterOptions, cena_kupna: e.target.value })}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControlLabel
                                control={<Checkbox checked={filterOptions.oc} onChange={(e) => setFilterOptions({ ...filterOptions, oc: e.target.checked })} />}
                                label="OC"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControlLabel
                                control={<Checkbox checked={filterOptions.ac} onChange={(e) => setFilterOptions({ ...filterOptions, ac: e.target.checked })} />}
                                label="AC"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControlLabel
                                control={<Checkbox checked={filterOptions.nw} onChange={(e) => setFilterOptions({ ...filterOptions, nw: e.target.checked })} />}
                                label="NW"
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Collapse>

            {/* Tabela pojazdów */}
            <TableContainer component={Paper} style={{ marginTop: '20px' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Nr Rej</TableCell>
                            <TableCell>Marka</TableCell>
                            <TableCell>Model</TableCell>
                            <TableCell>VIN</TableCell>
                            <TableCell>Cena Kupna</TableCell>
                            <TableCell>Przegląd</TableCell>
                            <TableCell>Ubezpieczenie</TableCell>
                            <TableCell>Numer Polisy</TableCell>
                            <TableCell>Dane Ubezpieczyciela</TableCell>
                            <TableCell>OC</TableCell>
                            <TableCell>AC</TableCell>
                            <TableCell>NW</TableCell>
                            <TableCell>Akcje</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredFlota.map((pojazd) => (
                            <TableRow key={pojazd.id}>
                                <TableCell>{pojazd.nr_rejestracyjny}</TableCell>
                                <TableCell>{pojazd.marka}</TableCell>
                                <TableCell>{pojazd.model}</TableCell>
                                <TableCell>{pojazd.vin}</TableCell>
                                <TableCell>{pojazd.cena_kupna}</TableCell>
                                <TableCell>{pojazd.przeglad}</TableCell>
                                <TableCell>{pojazd.ubezpieczenie}</TableCell>
                                <TableCell>{pojazd.numer_polisy}</TableCell>
                                <TableCell>{pojazd.dane_ubezpieczyciela}</TableCell>
                                <TableCell>{pojazd.oc}</TableCell>
                                <TableCell>{pojazd.ac}</TableCell>
                                <TableCell>{pojazd.nw}</TableCell>
                                <TableCell>
                                    <Button onClick={() => handleEdit(pojazd)} color="warning">Edytuj</Button>
                                    <Button onClick={() => handleZlomowanie(pojazd.id)} color="secondary">Złomuj</Button>
                                    <Button onClick={() => handleSprzedaz(pojazd.id, 10000)} color="primary">Sprzedaj</Button>
                                    <Button onClick={() => handleDelete(pojazd.id)} color="error" variant="outlined" style={{ marginLeft: '10px' }}>
                                        Usuń
                                    </Button>
                                </TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
                <DialogTitle>Dodaj Pojazd</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleFormSubmit}>
                        <TextField
                            label="Numer Rejestracyjny"
                            name="nr_rejestracyjny"
                            value={form.nr_rejestracyjny}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Marka"
                            name="marka"
                            value={form.marka}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Model"
                            name="model"
                            value={form.model}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="VIN"
                            name="vin"
                            value={form.vin}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Cena Kupna"
                            name="cena_kupna"
                            value={form.cena_kupna}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Stan Licznika"
                            name="stan_licznika"
                            value={form.stan_licznika}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Stan Paliwa"
                            name="stan_paliwa"
                            value={form.stan_paliwa}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Stan Pojazdu"
                            name="stan_pojazdu"
                            value={form.stan_pojazdu}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Akcesoria"
                            name="akcesoria"
                            value={form.akcesoria}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={form.oc} onChange={handleInputChange} name="oc" />}
                            label="OC"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={form.ac} onChange={handleInputChange} name="ac" />}
                            label="AC"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={form.nw} onChange={handleInputChange} name="nw" />}
                            label="NW"
                        />
                        <TextField
                            label="Numer Polisy"
                            name="numer_polisy"
                            value={form.numer_polisy}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Dane Ubezpieczyciela"
                            name="dane_ubezpieczyciela"
                            value={form.dane_ubezpieczyciela}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                        />
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => resetForm()} color="secondary">Anuluj</Button>
                    {editMode ? (
                        <Button onClick={handleUpdateSubmit} color="primary">Aktualizuj</Button>
                    ) : (
                        <Button onClick={handleFormSubmit} color="primary">Dodaj</Button>
                    )}
                </DialogActions>

            </Dialog>
            <Dialog
                open={confirmZlomowanieOpen}
                onClose={() => setConfirmZlomowanieOpen(false)}
            >
                <DialogTitle>Potwierdzenie złomowania</DialogTitle>
                <DialogContent>
                    Czy na pewno chcesz zezłomować ten pojazd?
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmZlomowanieOpen(false)} color="primary">
                        Anuluj
                    </Button>
                    <Button onClick={confirmZlomowanie} color="secondary">
                        Złomuj
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Flota;