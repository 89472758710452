import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Snackbar,
    Alert,
} from '@mui/material';
import { useConfirmDelete } from './ConfirmDelete';
import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import { saveAs } from 'file-saver';

const UmowaNajmuFirma = () => {
    const [form, setForm] = useState({
        nazwa: '',
        data: '',
        godzina: '',
        dzien: '',
        dogodziny: '',
        dodzien: '',
        dodatkowe: '',
        selectedVehicle: '',
        selectedFirma: '',
    });
    const [documents, setDocuments] = useState([]);
    const [flota, setFlota] = useState([]);
    const [firmy, setFirmy] = useState([]);
    const [open, setOpen] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const { confirmDelete, ConfirmationDialog } = useConfirmDelete();

    // Pobieranie dokumentów, floty i firm przy załadowaniu komponentu
    useEffect(() => {
        fetchDokumenty();
        fetchFlota();
        fetchFirmy();
    }, []);

    const fetchDokumenty = async () => {
        try {
            const response = await fetch('https://autosolgasystem.pl/django-app/dbview/dokumenty/');
            const data = await response.json();
            const filteredDocuments = data.filter((doc) => doc.rodzaj === 'umowa najmu firma');
            setDocuments(filteredDocuments);
        } catch (error) {
            console.error('Błąd pobierania dokumentów:', error);
            setSnackbar({ open: true, message: 'Błąd pobierania dokumentów', severity: 'error' });
        }
    };

    const fetchFlota = async () => {
        try {
            const response = await fetch('https://autosolgasystem.pl/django-app/dbview/');
            const data = await response.json();
            setFlota(data);
        } catch (error) {
            console.error('Błąd pobierania floty:', error);
            setSnackbar({ open: true, message: 'Błąd pobierania floty', severity: 'error' });
        }
    };

    const fetchFirmy = async () => {
        try {
            const response = await fetch('https://autosolgasystem.pl/django-app/dbview/firma_disp');
            const data = await response.json();
            setFirmy(data);
        } catch (error) {
            console.error('Błąd pobierania firm:', error);
            setSnackbar({ open: true, message: 'Błąd pobierania firm', severity: 'error' });
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const handleSnackbarClose = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    const generateDocx = async () => {
        const templateUrl = '/UmowaNajmuFirma.docx';
        const response = await fetch(templateUrl);

        if (!response.ok) {
            console.error('Błąd pobierania szablonu:', response.statusText);
            setSnackbar({ open: true, message: 'Błąd pobierania szablonu', severity: 'error' });
            return;
        }

        const blob = await response.blob();
        const arrayBuffer = await blob.arrayBuffer();
        const zip = new PizZip(arrayBuffer);
        const doc = new Docxtemplater(zip, { paragraphLoop: true, lineBreaks: true });

        const selectedVehicle = flota.find((v) => v.id === form.selectedVehicle) || {};
        const selectedFirma = firmy.find((f) => f.id === form.selectedFirma) || {};

        doc.setData({
            nazwa: form.nazwa,
            data: form.data,
            godzina: form.godzina,
            dzien: form.dzien,
            dogodziny: form.dogodziny,
            dodzien: form.dodzien,
            dodatkowe: form.dodatkowe,
            marka: selectedVehicle.marka,
            model: selectedVehicle.model,
            registrationNumber: selectedVehicle.registrationNumber,
            firmaNazwa: selectedFirma.nazwa_firmy,
            firmaNip: selectedFirma.nip,
            currentDate: new Date().toLocaleDateString(),
        });

        try {
            doc.render();
            const out = doc.getZip().generate({ type: 'blob' });

            // Zapisz dokument lokalnie
            saveAs(out, `${form.nazwa}.docx`);

            // Wyślij dokument do backendu
            const formData = new FormData();
            formData.append('nazwa', form.nazwa);
            formData.append('data', form.data);
            formData.append('status', 'Nowy');
            formData.append('rodzaj', 'umowa najmu firma');
            formData.append('plik', out, `${form.nazwa}.docx`);

            const createResponse = await fetch('https://autosolgasystem.pl/django-app/dbview/dokumenty/create/', {
                method: 'POST',
                body: formData,
            });

            const result = await createResponse.json();
            if (result.status === 'success') {
                setSnackbar({ open: true, message: 'Dokument dodany pomyślnie', severity: 'success' });
                fetchDokumenty(); // Odśwież dane
                resetForm();
            } else {
                setSnackbar({ open: true, message: result.message, severity: 'error' });
            }
        } catch (error) {
            console.error('Błąd podczas generowania pliku:', error);
            setSnackbar({ open: true, message: 'Błąd podczas generowania pliku', severity: 'error' });
        }
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        generateDocx();
    };

    const handleDeleteClick = async (id) => {
        const confirmed = await confirmDelete('Czy na pewno chcesz usunąć ten dokument?');
        if (confirmed) {
            try {
                const response = await fetch(`https://autosolgasystem.pl/django-app/dbview/dokumenty/delete/${id}/`, {
                    method: 'DELETE',
                });
                const result = await response.json();
                if (result.status === 'success') {
                    setSnackbar({ open: true, message: 'Dokument usunięty pomyślnie', severity: 'success' });
                    fetchDokumenty(); // Odśwież dane
                } else {
                    setSnackbar({ open: true, message: result.message, severity: 'error' });
                }
            } catch (error) {
                console.error('Błąd podczas usuwania dokumentu:', error);
                setSnackbar({ open: true, message: 'Błąd podczas usuwania dokumentu', severity: 'error' });
            }
        }
    };

    const resetForm = () => {
        setForm({
            nazwa: '',
            data: '',
            godzina: '',
            dzien: '',
            dogodziny: '',
            dodzien: '',
            dodatkowe: '',
            selectedVehicle: '',
            selectedFirma: '',
        });
        setOpen(false);
    };

    return (
        <Box className="main-content">
            <Typography variant="h4" gutterBottom>Dokumenty - umowa najmu firma</Typography>
            <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
                Dodaj Dokument
            </Button>

            {/* Formularz dodawania */}
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Dodaj Dokument</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleFormSubmit}>
                        <TextField
                            name="nazwa"
                            label="Nazwa Dokumentu"
                            value={form.nazwa}
                            onChange={handleInputChange}
                            required
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            name="data"
                            label="Data"
                            type="date"
                            value={form.data}
                            onChange={handleInputChange}
                            required
                            fullWidth
                            margin="normal"
                            InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                            name="godzina"
                            label="Godzina"
                            value={form.godzina}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            name="dzien"
                            label="Dzień"
                            value={form.dzien}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            name="dogodziny"
                            label="Do Godziny"
                            value={form.dogodziny}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            name="dodzien"
                            label="Do Dnia"
                            value={form.dodzien}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            name="dodatkowe"
                            label="Dodatkowe Informacje"
                            value={form.dodatkowe}
                            onChange={handleInputChange}
                            fullWidth
                            multiline
                            margin="normal"
                        />
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Wybierz pojazd</InputLabel>
                            <Select
                                name="selectedVehicle"
                                value={form.selectedVehicle}
                                onChange={handleInputChange}
                                required
                            >
                                <MenuItem value=""><em>-- Wybierz pojazd --</em></MenuItem>
                                {flota.map((vehicle) => (
                                    <MenuItem key={vehicle.id} value={vehicle.id}>
                                        {vehicle.marka} - {vehicle.model}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth margin="normal">
                            <InputLabel>Wybierz firmę</InputLabel>
                            <Select
                                name="selectedFirma"
                                value={form.selectedFirma}
                                onChange={handleInputChange}
                                required
                            >
                                <MenuItem value=""><em>-- Wybierz firmę --</em></MenuItem>
                                {firmy.map((firma) => (
                                    <MenuItem key={firma.id} value={firma.id}>
                                        {firma.nazwa_firmy}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="secondary">
                        Anuluj
                    </Button>
                    <Button onClick={handleFormSubmit} color="primary">
                        Zapisz
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Tabela z dokumentami */}
            <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Nazwa</TableCell>
                            <TableCell>Data</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Plik</TableCell>
                            <TableCell>Akcje</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {documents.map((doc) => (
                            <TableRow key={doc.id}>
                                <TableCell>{doc.nazwa}</TableCell>
                                <TableCell>{doc.data}</TableCell>
                                <TableCell>{doc.status}</TableCell>
                                <TableCell>
                                    <Button
                                        onClick={() =>
                                            window.open(`https://autosolgasystem.pl/media/${doc.plik}`, '_blank')
                                        }
                                        color="primary"
                                    >
                                        Pobierz
                                    </Button>
                                </TableCell>
                                <TableCell>
                                    <Button onClick={() => handleDeleteClick(doc.id)} color="secondary">
                                        Usuń
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Snackbar do wyświetlania komunikatów */}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>

            <ConfirmationDialog />
        </Box>
    );
};

export default UmowaNajmuFirma;